//==============================
// Global
//==============================

FastClick.attach(document.body);

/*
$('.element').waypoint(function(direction) {
  var thisElement = $(this.element);
  //stuff
  this.destroy();
}, {
  offset: '50%'
});
*/

$('.home-video-inner').fitVids();

$('.js-home-video-warning-button').click(function() {
  $('.home-video-warning').remove();
});
