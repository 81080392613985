//==============================
// Header
//==============================

var header = $('.header');
var button = $('.header-nav-button');
var nav = $('.header-nav');

// button
button.click(function() {
  header.toggleClass('open');
  button.toggleClass('open');
  nav.toggleClass('open');
});

$('.js-scroll-to-anchor').click(function() {
  header.removeClass('open');
  button.removeClass('open');
  nav.removeClass('open');
});
