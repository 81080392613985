//==============================
// Band Slider
//==============================

$('.home-band-items').slick({
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1
});
